import {FeatureConfig} from '@/config/featureToggles'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'

export const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  HOME: '/',
  LIST_NBAS: '/nba/list',
  SUCCESSFUL_LOGIN: '/success',
  CREATE_JOURNEY: '/create',
  CREATE_NBA: '/nba/create',
  EDIT_JOURNEY: '/journey/:id',
  EDIT_NBA: '/nba/:id',
  NBA_REPORTS: '/nba/store/reports',
  TASK_REPORTS: '/nba/task/reports',
  SETTINGS: '/settings',
  EVENTS: '/events',
  ERROR: '/error',
  ERROR_403: '/error/403',
  ERROR_500: '/error/500',
}

export const BASE_URL_PLACEHOLDER = {
  ADMIN_PORTAL: `{{${FeatureConfig.apiAdminUrl}}}`,
  EVENTS: `{{${FeatureConfig.apiEventsUrl}}}`,
}
export const ENDPOINTS = {
  // MARK: - Journey Endpoints
  LOAD_JOURNEYS: '/journeys',
  EDIT_JOURNEY: '/journeys/:id',
  SAVE_JOURNEY: '/journeys',
  PUBLISH_JOURNEY: '/journeys/:id/publish',
  JOURNEY_STATUS: '/journeys/:id/status',
  EXPORT_REPORT_STATUS_REPORT: '/journeys/:id/export/executions',
  EXPORT_REPORT_STATUS_REPORT_DATA: '/journeys/:id/export/executions/data',
  LOAD_NBAS: '/nbas',
  EDIT_NBA: '/nbas/:id',
  SAVE_NBA: '/nbas',
  PUBLISH_NBA: '/publish',
  PROCCESS_FILE: '/processFile',
  EXPORT_FILE: '/download/report',
  DELETE_FILE: '/fileOperations',
  NBA_STATUS: '/nbas/:id/status',
  NBA_STORE_REPORT: '/reports/store',
  NBA_TASK_REPORT: '/reports/task',
  EXPORT_NBA_STATUS_REPORT: '/nbas/:id/export/executions',
  EXPORT_NBA_STATUS_REPORT_DATA: '/nbas/:id/export/executions/data',
  EXPORT_REQUIRES_INTERVENTION: '/export',
  EXPORT_REQUIRES_INTERVENTION_DATA: '/export',
  SETTINGS: '/settings',
  LOAD_STEP_CONFIG: (type: NodeTypeEnum.sendPush | NodeTypeEnum.sendWhatsApp) => {
    switch (type) {
      case NodeTypeEnum.sendPush:
        return '/step/config/push-notification'
      case NodeTypeEnum.sendWhatsApp:
        return '/step/config/whatsapp'
      default:
        throw new Error(`Unsupported step type: ${type}`)
    }
  },
  LOAD_TEMPLATES: '/message-templates/:notificationType',
  // MARK: - Admin-Portal Endpoints
  LOAD_USER_ROLES: `${BASE_URL_PLACEHOLDER.ADMIN_PORTAL}/v1/users/auth/cct`,

  // MARK: - Real-Time Events Endpoints
  LOAD_EVENTS: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications`,
  CREATE_EVENT: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications`,
  LOAD_EVENT: `${BASE_URL_PLACEHOLDER.EVENTS}/eventnotifications/:id`,

  // master tables
  LOAD_COUNTRIES: '/countries',
  LOAD_LANGUAGES: '/languages',
  LOAD_TASK_TYPES: '/taskTypes',
  LOAD_TASKS: '/tasks',
  UPLOAD: '/fileOperations',
  CONFIG_TYPES: '/configTypes',
}

export const DEFAULT_INTL = 'en-US'

export const STEP_CONFIG = {
  DATA_QUERY_KEY: 'stepConfig',
  DEFAULT_NODE_Y_DISTANCE: 60,
  DEFAULT_ZOOM_LEVEL: 100,
  DEFAULT_OFFSET_POSITION: {
    x: 0,
    y: 0,
  },
  DEFAULT_ENTRY_CONDITION_POSITION: {
    x: 300,
    y: 50,
  },
}

export const JOURNEY_QUERY_KEY = 'journey'
export const JOURNEY_STATUS_EXECUTION_QUERY_KEY = 'journeyStatus'

export const COUNTRIES_QUERY_KEY = 'countries'
export const LANGUAGES_QUERY_KEY = 'languages'
export const TASK_TYPES_QUERY_KEY = 'tasktypes'
export const TASKS_QUERY_KEY = 'tasks'
export const SUGGESIONS_QUERY_KEY = 'suggestions'

export const NBA_QUERY_KEY = 'nba'
export const NBA_STATUS_EXECUTION_QUERY_KEY = 'nbaStatus'

export const FEEDBACK_DURATION = 5 * 1000 // 5 seconds
export const EXECUTION_STATUS_POOLING_INTERVAL = 15 * 1000 // 15 seconds

export const OKTA_AUTH_CONFIG_KEY = 'okta-auth-config'
export const OKTA_TOKEN_STORAGE_KEY = 'okta-token-storage'
export const OKTA_FORBIDDEN_ERROR = 'User is not assigned to the client application.'

export const timezones = {
  UTC: 'UTC',
  AR: 'America/Argentina/Buenos_Aires',
  BR: 'America/Sao_Paulo',
  CL: 'America/Santiago',
  MX: 'America/Mexico_City',
  PL: 'Poland',
  TR: 'Turkey',
  US: 'America/Los_Angeles',
  XX: 'SUT Timezone',
} as const

export type Timezone = (typeof timezones)[keyof typeof timezones]
