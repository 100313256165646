import {isNew, isPublishedOrCompleted} from '@/helpers/statusHandler'
import {useAppSelector} from '@/store'
import {useMemo} from 'react'

// MARK: - Hook

export const useNBAMetaConfig = () => {
  const nbaConfig = useAppSelector(state => state.nbaConfig)

  const _isPublishedOrCompleted = useMemo(() => isPublishedOrCompleted(nbaConfig.status), [nbaConfig.status])
  const _isNew = useMemo(() => isNew(nbaConfig.status), [nbaConfig.status])

  return {
    ...nbaConfig,
    isPublishedOrCompleted: _isPublishedOrCompleted,
    isNew: _isNew,
  }
}
