import {PeriodSchema} from '@/types/Period'
import {z} from 'zod'
import {NodeTypeEnum} from './NodeTypeEnum'

const ExecutionStatusType = {
  created: 'CREATED',
  running: 'RUNNING',
  completed: 'COMPLETED',
  canceled: 'CANCELED',
} as const

const StepStatusType = {
  created: 'CREATED',
  running: 'RUNNING',
  onHold: 'ON_HOLD',
  skipped: 'SKIPPED',
  failed: 'FAILED',
  executed: 'EXECUTED',
  canceled: 'CANCELED',
  interrupted: 'INTERRUPTED',
} as const

const ExecutionStatusTypesSchema = z.nativeEnum(ExecutionStatusType).or(z.nativeEnum(StepStatusType))
export type ExecutionStatusTypes = z.infer<typeof ExecutionStatusTypesSchema>

const ExecutionStatusSchema = z.record(ExecutionStatusTypesSchema, z.number())
export type ExecutionStatus = z.infer<typeof ExecutionStatusSchema>

const StepStatusSchema = z.record(z.nativeEnum(StepStatusType), z.number())

const StepExecutionStatusSchema = z.record(
  z.object({
    id: z.string(),
    type: z.nativeEnum(NodeTypeEnum),
    status: StepStatusSchema,
  })
)

const NBAExecutionStatusSchema = z.object({
  nba: z
    .object({
      id: z.string(),
    })
    .optional(),
  period: PeriodSchema.optional(),
  executions: z.object({
    total: z.number(),
    status: ExecutionStatusSchema,
    steps: StepExecutionStatusSchema,
  }),
})
export type NBAExecutionStatus = z.infer<typeof NBAExecutionStatusSchema>

// MARK: - Single NBA execution status

export const GetNBAStatusRequestSchema = z.void()
export const GetNBAStatusResponseSchema = NBAExecutionStatusSchema

export type GetNBAStatusRequest = z.infer<typeof GetNBAStatusRequestSchema>
export type GetNBAStatusResponse = z.infer<typeof GetNBAStatusResponseSchema>
