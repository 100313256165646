import {PageableResponseSchema} from '@/types/Pagination'
import {z} from 'zod'

export const Content = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  active: z.boolean(),
})

export const ArrayContent = z.array(Content)

export const LanguagesResponseSchema = PageableResponseSchema(Content)
export const GetLanguagesRequestSchema = z.object({
  countryId: z.string().or(z.number()),
})

export type LanguagesType = z.infer<typeof ArrayContent>

export type GetLanguagesRequest = z.infer<typeof GetLanguagesRequestSchema>

export type GetLanguagesResponse = z.infer<typeof LanguagesResponseSchema>
