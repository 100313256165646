import {useWidgetInfo} from '@/components/Canvas/hooks/useWidgetInfo'
import {Icon} from 'pepsico-ds'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {SendSMSNodeModel} from './SendSMSNodeModel'

export interface SendSMSNodeWidgetProps extends CustomNodeWidgetProps {
  node: SendSMSNodeModel
}

export const SendSMSNodeWidget = ({engine, node, ...props}: SendSMSNodeWidgetProps) => {
  const {formatMessage, haveWarnings, isSelected, portBottom, portTop} = useWidgetInfo(node)

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portTop={portTop}
      portBottom={portBottom}
      isSelected={isSelected}
      haveWarnings={haveWarnings}
      classNameWidgetWrapper="node-widget type-send-sms"
    >
      <div className={`flex items-center justify-center ${haveWarnings ? 'gap-2' : 'gap-3'}`}>
        <div className="node-icon" data-testid="send-sms-icon">
          <Icon icon="sms" size="small" className="$color-icon" />
        </div>
        <div className="node-content">
          <p className="node-name">{formatMessage({id: 'NBA_BUILDER.SEND_SMS_WIDGET_LABEL'})}</p>
        </div>
      </div>
    </CustomNodeWidget>
  )
}
