import {Icon} from 'pepsico-ds'
import {useIntl} from 'react-intl'

type NodeHasWarningsMessageProps = {
  haveWarnings?: boolean
}

export const NodeHasWarningsMessage = ({haveWarnings = false}: NodeHasWarningsMessageProps) => {
  const {formatMessage} = useIntl()

  if (!haveWarnings) {
    return null
  }

  return (
    <div className="my-1 flex w-full items-center justify-center gap-1">
      <Icon icon="error" size="small" className="$color-text-secondary h-4 w-4" />
      <p data-testid="missing-required-config-warning-msg" className="text-center text-xs text-text-secondary">
        {formatMessage({id: 'NBA_BUILDER.SHORT_REQUIRED_CONFIG_MESSAGE'})}
      </p>
    </div>
  )
}
