import {PageableResponseSchema} from '@/types/Pagination'
import {z} from 'zod'

export const Task = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  active: z.boolean(),
  taskTypeId: z.number().optional(),
})

export const TasksResponseSchema = PageableResponseSchema(Task)

export const TasksRequestSchema = z.object({
  taskTypeId: z.string().or(z.number()),
})

export type GetTasksRequest = z.infer<typeof TasksRequestSchema>

export type GetTasksResponse = z.infer<typeof TasksResponseSchema>
