import {RecurrenceDaySchema} from '@/types/Day'
import {NBAStatus} from '@/types/NBAStatus'
import {NBATypeSchema} from '@/types/NBAType'
import {PeriodSchema} from '@/types/Period'
import {RecurrenceSchema} from '@/types/Recurrence'
import {CountryCodeSchema} from '@/types/Templates'
import {z} from 'zod'
import {PageableResponseSchema} from './Pagination'
import {LanguageSchema, TaskSchema} from '@/types/TaskSchema'
import {TimeExecutionWindowsSchema} from '@/types/TimeExecution'
import {StepEntryConditionSchema, StepsSchema} from '@/types/Steps'
import {RewardTypeSchema} from '@/types/rewardType'
import {ThresholdTypeSchema} from '@/types/threshholdType'

// MARK: - NBA template config

const NBASchema = z.object({
  id: z.string(),
  name: z.string().max(100),
  description: z.string().max(100).optional(),
  countryId: z.number().or(z.string()),
  countryCode: CountryCodeSchema.optional(),
  statusId: z.number().optional(),
  status: z.nativeEnum(NBAStatus).nullable(),
  type: NBATypeSchema.optional(),
  recurrence: RecurrenceSchema.optional(),
  recurrenceDays: RecurrenceDaySchema.optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  displayStartDate: z.string().optional(),
  displayEndDate: z.string().optional(),
  // period: PeriodSchema.optional(),
  // displayPeriod: PeriodSchema.optional(),
  fixNumber: z.number().optional(),
  languages: z.array(LanguageSchema).optional(),
  tasks: z.array(TaskSchema).optional(),
  filePath: z.string().optional(),
  awardAssignment: z.string().optional(),
  fixRewardPoints: z.string().optional(), //z.union([z.string(), z.number()]),
  fixThreshold: z.string().optional(), //z.union([z.string(), z.number()]),
  onAwardDate: z.string().optional(),
  onTaskCompletion: z.boolean().optional(),
  timeExecutionWindows: TimeExecutionWindowsSchema.optional(),
  entryCondition: StepEntryConditionSchema.optional(),
  steps: StepsSchema.optional(),
  publishedDate: z.string().nullable().optional(),
  createDate: z.string().nullable().optional(),
  updateDate: z.string().nullable().optional(),
})

export type NBA = z.infer<typeof NBASchema>

// MARK: - NBAs

const NBAListItemSchema = z.object({
  id: NBASchema.shape.id,
  countryId: NBASchema.shape.countryId,
  countryCode: NBASchema.shape.countryCode,
  languages: NBASchema.shape.languages,
  name: NBASchema.shape.name,
  description: NBASchema.shape.description,
  statusId: NBASchema.shape.statusId,
  status: NBASchema.shape.status,
  startDate: PeriodSchema.shape.start,
  endDate: PeriodSchema.shape.end,
  displayStartDate: PeriodSchema.shape.start,
  displayEndDate: PeriodSchema.shape.end,
  publishedDate: NBASchema.shape.publishedDate,
  createDate: NBASchema.shape.createDate,
  updateDate: NBASchema.shape.updateDate,
  tasks: NBASchema.shape.tasks,
  fixRewardPoints: NBASchema.shape.fixRewardPoints,
  fixThreshold: NBASchema.shape.fixThreshold,
  onAwardDate: NBASchema.shape.onAwardDate,
})

export type NBAListItem = z.infer<typeof NBAListItemSchema>

export const GetNBAsRequestSchema = z.object({
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  sort: z.string().optional(),
  query: z.string().optional(),
  countryCode: CountryCodeSchema,
})
export const GetNBAsResponseSchema = PageableResponseSchema(NBAListItemSchema)

export type GetNBAsRequest = z.infer<typeof GetNBAsRequestSchema>
export type GetNBAsResponse = z.infer<typeof GetNBAsResponseSchema>

// MARK: - Single NBA by id

export const GetNBARequestSchema = z.void()
export const GetNBAResponseSchema = NBASchema

export type GetNBARequest = z.infer<typeof GetNBARequestSchema>
export type GetNBAResponse = z.infer<typeof GetNBAResponseSchema>

// MARK - Delete NBA by id

export const DeleteNBARequestSchema = z.void()
export const DeleteNBAResponseSchema = NBASchema

export type DeleteNBAResponse = z.infer<typeof NBASchema>

// MARK: - New NBA

export const SaveNBARequestSchema = NBASchema.extend({
  id: z.optional(NBASchema.shape.id),
  status: z.optional(NBASchema.shape.status),
})

export const SaveNBAResponseSchema = z.object({
  id: NBASchema.shape.id,
  status: NBASchema.shape.status,
})

export type SaveNBARequest = z.infer<typeof SaveNBARequestSchema>
export type SaveNBAResponse = z.infer<typeof SaveNBAResponseSchema>

// MARK: - Reports responses

export const GetReportResponseSchema = z.object({
  path: z.string(),
})
export type GetReportResponse = z.infer<typeof GetReportResponseSchema>

export const GetNBAReportRequestSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
})
export type GetNBAReportRequest = z.infer<typeof GetNBAReportRequestSchema>

export const ReportWorksheetSchema = z.object({
  name: z.string(),
  columns: z.array(z.object({name: z.string()})),
  records: z.array(z.record(z.string(), z.unknown().nullable())),
})

export type ReportWorksheet = z.infer<typeof ReportWorksheetSchema>

export const GetReportDataResponseSchema = z.object({
  content: z.array(ReportWorksheetSchema),
})
export type GetReportDataResponse = z.infer<typeof GetReportDataResponseSchema>

export type GetNBAReportDataRequest = z.infer<typeof GetNBAReportRequestSchema>

// MARK: - Generic responses

export const NoContentResponseSchema = z.string()
export type NoContentResponse = z.infer<typeof NoContentResponseSchema>

//create NBA Response

export const CreateNBAResponseSchema = z.object({
  success: z.boolean(),
  data: NBAListItemSchema.optional(),
  errorMessage: z.string().optional(),
})

export const LanguageScheema = z.object({
  language: z.string(),
  name: z.string(),
})

export const CreateNBARequestSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  languages: z.array(LanguageSchema).optional(),
  countryId: z.string().or(z.number()),
  countryCode: z.string(),
  statusId: z.number(),
  status: z.nativeEnum(NBAStatus),
  period: PeriodSchema.optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  displayPeriod: PeriodSchema.optional(),
  displayStartDate: z.string().optional(),
  displayEndDate: z.string().optional(),
  tasks: z.array(TaskSchema).optional(),
  onTaskCompletion: z.boolean().optional(),
  onAwardDate: z.string().optional(),
  fixReward: RewardTypeSchema,
  fixRewardPoints: z.number().or(z.string()).optional(),
  treshhold: ThresholdTypeSchema,
  fixThreshold: z.string(),
})

export const PublishNBARequestSchema = z.object({
  id: z.string().optional(),
})

export type PublishNBARequest = z.infer<typeof PublishNBARequestSchema>
export type CreateNBARequest = z.infer<typeof CreateNBARequestSchema>
export type CreateNBAResponse = z.infer<typeof CreateNBAResponseSchema>
