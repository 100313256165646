import {z} from 'zod'

export const FileUploadRequestSchema = z.instanceof(FormData)

export const FileUploadResponseSchema = z.object({
  success: z.boolean(),
  fileType: z.string(),
  filePath: z.string(),
  nbaId: z.string().optional(),
})

export type FileUploadRequest = z.infer<typeof FileUploadRequestSchema>
export type FileUploadResponse = z.infer<typeof FileUploadResponseSchema>

export const FileDeleteRequestSchema = z.object({
  nbaId: z.string(),
  fileName: z.string(),
  filePath: z.string(),
})
export const FileDeleteResponseSchema = z.object({
  success: z.boolean(),
})

export const FileDeleteBodyRequestSchema = z.void()
export type FileDeleteBodyRequest = z.infer<typeof FileDeleteBodyRequestSchema>
export type FileDeleteRequest = z.infer<typeof FileDeleteRequestSchema>
export type FileDeleteResponse = z.infer<typeof FileDeleteResponseSchema>

/** proccess file */

export const ProccessFileRequestSchema = z.object({
  nbaId: z.string(),
  filePath: z.string(),
  taskId: z.string(),
})

export const ProccessFileResponseSchema = z.object({
  success: z.boolean(),
})
export type ProccessFileRequest = z.infer<typeof ProccessFileRequestSchema>
export type ProccessFileResponse = z.infer<typeof ProccessFileResponseSchema>
