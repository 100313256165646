import {PageableResponseSchema} from '@/types/Pagination'
import {z} from 'zod'

export const ConfigType = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
  active: z.boolean(),
})

export const ConfigTypesResponseSchema = PageableResponseSchema(ConfigType)

export const ConfigTypesRequestSchema = z.object({
  taskId: z.string().or(z.number()).optional(),
  fileType: z.string(),
})
export type GetConfigTypesRequest = z.infer<typeof ConfigTypesRequestSchema>

export type GetConfigTypesResponse = z.infer<typeof ConfigTypesResponseSchema>
