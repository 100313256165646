import {useWidgetInfo} from '@/components/Canvas/hooks/useWidgetInfo'
import {WhatsAppIcon} from '@Icons'
import {CustomNodeWidget, CustomNodeWidgetProps} from '../CustomNodeWidget'
import {SendWhatsAppNodeModel} from './SendWhatsAppNodeModel'

export interface SendWhatsAppNodeWidgetProps extends CustomNodeWidgetProps {
  node: SendWhatsAppNodeModel
}

export const SendWhatsAppNodeWidget = ({engine, node, ...props}: SendWhatsAppNodeWidgetProps) => {
  const {formatMessage, haveWarnings, isSelected, portBottom, portTop} = useWidgetInfo(node)

  return (
    <CustomNodeWidget
      {...props}
      engine={engine}
      node={node}
      portTop={portTop}
      portBottom={portBottom}
      isSelected={isSelected}
      haveWarnings={haveWarnings}
      classNameWidgetWrapper="node-widget type-send-whatsapp"
    >
      <div data-cy="whatsapp-node-widget" className="flex items-center justify-center gap-3">
        <div className="node-icon" data-testid="send-whatsapp-icon">
          <WhatsAppIcon className="h-4 w-4 fill-icon" />
        </div>
        <div className="node-content">
          <p className="node-name">{formatMessage({id: 'NBA_BUILDER.SEND_WHATSAPP_WIDGET_LABEL'})}</p>
        </div>
      </div>
    </CustomNodeWidget>
  )
}
