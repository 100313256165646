import {NBAStatus} from '@/types/NBAStatus'
import {Icon, Tag} from 'pepsico-ds'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'

type NBAStatusBadgeProps = {
  status: NBAStatus | null
}

type ReportNBAStatusBadgeProps = {
  thresholdCompletion: string | number | undefined
  threshold: string | number | undefined
}

export const NBAStatusBadge = ({status}: NBAStatusBadgeProps) => {
  const {formatMessage} = useIntl()
  const label = formatMessage({id: `STATUS.${status}`})

  const normalizedStatus = useMemo(() => {
    switch (status) {
      case NBAStatus.new:
      case NBAStatus.draft:
      case NBAStatus.saved:
        return NBAStatus.draft
      default:
        return status
    }
  }, [status])

  return status == NBAStatus.needs_review ? (
    <span className={`badge status-${normalizedStatus?.replace(' ', '-')}-badge flex  flex-row rounded`}>
      {label} <Icon icon="mobiledata_off" size="medium" className="$color-icon" />
    </span>
  ) : (
    <Tag
      data-testid={`nba-status-${normalizedStatus}-badge`}
      data-cy={`nba-status-${normalizedStatus}-badge`}
      color="transparent"
      className={`status-${normalizedStatus}-badge`}
      size="large"
      text={label}
      isCopyable={false}
      isRemovable={false}
    />
  )
}

export const NBASReportStatusBadge = ({threshold, thresholdCompletion}: ReportNBAStatusBadgeProps) => {
  const {formatMessage} = useIntl()
  let status = NBAStatus.new
  if (thresholdCompletion == 0) {
    status = NBAStatus.new
  } else if (Number(thresholdCompletion) < Number(threshold)) {
    status = NBAStatus.in_progress
  } else if (Number(thresholdCompletion) >= Number(threshold)) {
    status = NBAStatus.completed
  }
  const label = formatMessage({id: `REPORT_STATUS.${status}`})

  return (
    <Tag
      data-testid={`nba-status-${status}-badge`}
      data-cy={`nba-status-${status}-badge`}
      color="transparent"
      className={`report-status-${status}-badge`}
      size="large"
      text={label}
      isCopyable={false}
      isRemovable={false}
    />
  )
}
