import {PageableResponseSchema} from '@/types/Pagination'
import {z} from 'zod'

export const Content = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  active: z.boolean(),
})

export const ArrayContent = z.array(Content)

export const CountriesResponseSchema = PageableResponseSchema(Content)

export const GetCountriesRequestSchema = z.void()

export type CountryType = z.infer<typeof Content>

export type CountriesType = z.infer<typeof ArrayContent>

export type GetCountriesRequest = z.infer<typeof GetCountriesRequestSchema>

export type GetCountriesResponse = z.infer<typeof CountriesResponseSchema>
