import {PublicClientApplication, AuthenticationResult} from '@azure/msal-browser'
import {msalConfig, loginRequest} from './authConfig'

const msalInstance = new PublicClientApplication(msalConfig)

export const acquireToken = async (): Promise<{accessToken: string; idToken: string} | null> => {
  try {
    const accounts = msalInstance.getAllAccounts()
    if (!accounts.length) {
      throw new Error('No accounts found')
    }

    const {accessToken, idToken}: AuthenticationResult = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })

    return {
      accessToken,
      idToken,
    }
  } catch (error) {
    console.error('Error acquiring token:', error)
    return null
  }
}
