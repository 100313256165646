import {Link} from '@/shared-components/Link'
import {DataParams, ParamFilters} from '@/types/paramFilters/paramFilters'
import {getParamByValue, operators} from '@/utils/consts'
import {uniqueId} from 'lodash'
import {useIntl} from 'react-intl'

type EntryConditionsStepInfoProps = {
  stepData: ParamFilters
  openStepConfig?: () => void
}

export const EntryConditionsStepInfo = ({stepData, openStepConfig}: EntryConditionsStepInfoProps) => {
  const {formatMessage} = useIntl()

  const allParamsSelectors = stepData.paramsSelectors ?? []
  const paramsSelectorsToDisplay = allParamsSelectors.slice(0, 2)

  const hasItems = !!paramsSelectorsToDisplay.length
  const hasMoreQuantity = allParamsSelectors.length - 2
  const hasMore = hasMoreQuantity > 0

  const shouldDisplay = hasItems

  if (!shouldDisplay) {
    return null
  }

  return (
    <>
      {paramsSelectorsToDisplay.map(paramSelector => {
        const trait = getParamByValue(paramSelector.selectedParam as DataParams)?.displayValue
        const operator = operators().find(item => item.value === paramSelector?.selectedParamData?.operator)
          ?.displayValue
        const value = paramSelector?.selectedParamData?.value?.split(',').join(', ')

        if (!trait) {
          return null
        }

        return (
          <div key={`${trait}_${uniqueId()}`} className="flex gap-1">
            <span className="text-nowrap">{formatMessage({id: trait})} :</span>
            <span className="line-clamp-2 w-fit max-w-56 overflow-hidden overflow-ellipsis">
              {formatMessage({id: operator})} {value}
            </span>
          </div>
        )
      })}

      {hasMore && (
        <Link onClick={openStepConfig} className="mt-1" data-testid="has-more-items-link" data-cy="has-more-items-link">
          {formatMessage(
            {id: 'NBA_BUILDER.STEP_NODE_INFOS_MORE'},
            {
              quantity: hasMoreQuantity,
            }
          )}
        </Link>
      )}
    </>
  )
}
