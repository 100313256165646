import {z} from 'zod'
import {PageableResponseSchema} from './Pagination'
import {CountryCodeSchema} from '@/types/Templates'

export enum storeReportStatus {
  new = 'NEW',
  inprogress = 'IN PROGRESS',
  completed = 'COMPLETED',
}

const TaskSchema = z.object({
  taskTypeId: z.number().optional(),
  taskType: z.string().optional(),
  status: z.nativeEnum(storeReportStatus).or(z.string()).optional(),
  name: z.string().optional(),
  achievedValue: z.number().optional(),
  targetValue: z.number().optional(),
})

export type ReportTaskSchema = z.infer<typeof TaskSchema>

const StoreReportSchema = z.object({
  id: z.string().optional(),
  storeId: z.string().or(z.number()),
  nbaName: z.string().optional(),
  threshold: z.string().or(z.number()).optional(),
  reward: z.string().or(z.number()).optional(),
  thresholdCompletion: z.string().or(z.number()).optional(),
  tasks: z.array(TaskSchema),
})

export type StoreReportItem = z.infer<typeof StoreReportSchema>

// export const GetNBAsRequestSchema = z.object({
//   page: z.number().optional(),
//   pageSize: z.number().optional(),
//   sort: z.string().optional(),
//   query: z.string().optional(),
//   countryCode: CountryCodeSchema,
// })
// export const GetNBAsResponseSchema = PageableResponseSchema(NBAListItemSchema)

// export type GetNBAsRequest = z.infer<typeof GetNBAsRequestSchema>
// export type GetNBAsResponse = z.infer<typeof GetNBAsResponseSchema>

export const GetStoreReportRequestSchema = z.object({
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  sort: z.string().optional(),
  query: z.string().optional(),
  countryCode: CountryCodeSchema,
  nbaId: z.string().optional(),
})
export const GetStoreReportResponseSchema = PageableResponseSchema(StoreReportSchema)

export type GetStoreReportRequest = z.infer<typeof GetStoreReportRequestSchema>
export type GetReportResponse = z.infer<typeof GetStoreReportResponseSchema>

/** task */

export const GetTaskReportRequestSchema = z.object({
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  sort: z.string().optional(),
  query: z.string().optional(),
  countryCode: CountryCodeSchema,
  nbaId: z.string().optional(),
})

const StoreTaskSchema = z.object({
  id: z.string().optional(),
  nbaId: z.string().optional(),
  storeId: z.string(),
  taskType: z.string().optional(),
  name: z.string().optional(),
  taskDescrition: z.string().optional(),
  achievedValue: z.string().or(z.number()).optional(),
  targetValue: z.string().or(z.number()).optional(),
  threshold: z.string().or(z.number()).optional(),
  thresholdCompletion: z.string().or(z.number()).optional(),
  tasks: z.array(TaskSchema).optional(),
})

export type StoreTaskItem = z.infer<typeof StoreTaskSchema>

export const GetTaskReportResponseSchema = PageableResponseSchema(StoreTaskSchema)

export type GetTaskReportRequest = z.infer<typeof GetTaskReportRequestSchema>
export type GetTaskReportResponse = z.infer<typeof GetTaskReportResponseSchema>
