export const downloadFileHandler = (reportUrl: string | null, fileName?: string) => {
  if (!reportUrl) return

  const link = document.createElement('a')
  link.setAttribute('href', reportUrl)
  link.setAttribute('target', '_blank')
  link.setAttribute('download', fileName || '')
  link.click()
  console.log('sfsf')
}
