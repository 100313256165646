import {z} from 'zod'

export const LanguageSchema = z.object({
  languageId: z.number().or(z.string()).optional(),
  languageCode: z.string().optional(),
  languageName: z.string().optional(),
  displayText: z.string().optional(),
})

export enum TaskTypeNameEnum {
  Manual = 'Manual',
  Login = 'Login',
  ProductTarget = 'Product Target',
  Campaign = 'Join Campaign',
  DigitalOrder = 'Digital Order',
}

export type LanguageType = z.infer<typeof LanguageSchema>

export const TaskSchema = z.object({
  taskTypeId: z.number().or(z.string()).optional(),
  taskTypeName: z.nativeEnum(TaskTypeNameEnum).optional(),
  taskId: z.number().or(z.string()).optional(),
  taskName: z.string().optional(),
  languages: z.array(LanguageSchema).optional(),
  filePath: z.string().nullable().optional(),
  resultPath: z.array(z.string()).optional(),
})

export type TaskType = z.infer<typeof TaskSchema>
