import {pathBuilder} from '@/helpers/pathBuilder'
import {
  GetTemplatesRequest,
  GetTemplatesRequestSchema,
  GetTemplatesResponse,
  GetTemplatesResponseSchema,
} from '@/types/Templates'
import {ENDPOINTS} from '../config/constants'
import api, {HTTPMethod} from '../helpers/api'
import {
  GetJourneyStatusRequest,
  GetJourneyStatusRequestSchema,
  GetJourneyStatusResponse,
  GetJourneyStatusResponseSchema,
} from '../types/JourneyExecutionStatus'
import {
  DeleteJourneyRequestSchema,
  DeleteJourneyResponse,
  DeleteJourneyResponseSchema,
  GetJourneyReportDataRequest,
  GetJourneyReportRequest,
  GetJourneyReportRequestSchema,
  GetJourneyRequest,
  GetJourneyRequestSchema,
  GetJourneyResponse,
  GetJourneyResponseSchema,
  GetJourneysRequest,
  GetJourneysRequestSchema,
  GetJourneysResponse,
  GetJourneysResponseSchema,
  GetReportDataResponse,
  GetReportDataResponseSchema,
  GetReportResponse,
  GetReportResponseSchema,
  NoContentResponse,
  NoContentResponseSchema,
  SaveJourneyRequest,
  SaveJourneyRequestSchema,
  SaveJourneyResponse,
  SaveJourneyResponseSchema,
} from '../types/Journeys'

export const getJourneysService = (params: GetJourneysRequest, signal?: AbortSignal) => {
  return api<GetJourneysRequest, GetJourneysResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_JOURNEYS,
    requestSchema: GetJourneysRequestSchema,
    responseSchema: GetJourneysResponseSchema,
    signal,
  })(params)
}

export const getJourneyService = (id: string, signal?: AbortSignal) => {
  return api<GetJourneyRequest, GetJourneyResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EDIT_JOURNEY).withId(id).build(),
    requestSchema: GetJourneyRequestSchema,
    responseSchema: GetJourneyResponseSchema,
    signal,
  })()
}

export const deleteJourneyService = (id: string) => {
  return api<GetJourneyRequest, DeleteJourneyResponse>({
    method: HTTPMethod.DELETE,
    path: pathBuilder(ENDPOINTS.EDIT_JOURNEY).withId(id).build(),
    requestSchema: DeleteJourneyRequestSchema,
    responseSchema: DeleteJourneyResponseSchema,
  })()
}

export const saveJourneyService = api<SaveJourneyRequest, SaveJourneyResponse>({
  method: HTTPMethod.POST,
  path: ENDPOINTS.SAVE_JOURNEY,
  requestSchema: SaveJourneyRequestSchema,
  responseSchema: SaveJourneyResponseSchema,
})

export const updateJourneyService = (id: string, payload: SaveJourneyRequest) =>
  api<SaveJourneyRequest, SaveJourneyResponse>({
    method: HTTPMethod.PUT,
    path: pathBuilder(ENDPOINTS.EDIT_JOURNEY).withId(id).build(),
    requestSchema: SaveJourneyRequestSchema,
    responseSchema: SaveJourneyResponseSchema,
  })(payload)

export const publishJourneyService = (id: string) => {
  return api<GetJourneyRequest, GetJourneyResponse>({
    method: HTTPMethod.PUT,
    path: pathBuilder(ENDPOINTS.PUBLISH_JOURNEY).withId(id).build(),
    requestSchema: GetJourneyRequestSchema,
    responseSchema: GetJourneyResponseSchema,
  })()
}

export const getRequiresInterventionReportService = (id?: string, signal?: AbortSignal) => {
  return api<GetJourneyRequest, GetReportResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EXPORT_REQUIRES_INTERVENTION).withId(id).build(),
    requestSchema: GetJourneyRequestSchema,
    responseSchema: GetReportResponseSchema.or(NoContentResponseSchema),
    signal,
  })()
}

export const getRequiresInterventionReportDataService = (id?: string, signal?: AbortSignal) => {
  return api<GetJourneyRequest, GetReportDataResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EXPORT_REQUIRES_INTERVENTION_DATA).withId(id).build(),
    requestSchema: GetJourneyRequestSchema,
    responseSchema: GetReportDataResponseSchema.or(NoContentResponseSchema),
    signal,
  })()
}

export const getJourneyStatusService = (id: string, signal?: AbortSignal) => {
  return api<GetJourneyStatusRequest, GetJourneyStatusResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.JOURNEY_STATUS).withId(id).build(),
    requestSchema: GetJourneyStatusRequestSchema,
    responseSchema: GetJourneyStatusResponseSchema,
    signal,
  })()
}

export const getJourneyStatusReportService = (id: string, params: GetJourneyReportRequest, signal?: AbortSignal) => {
  return api<GetJourneyReportRequest, GetReportResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EXPORT_REPORT_STATUS_REPORT).withId(id).build(),
    requestSchema: GetJourneyReportRequestSchema,
    responseSchema: GetReportResponseSchema.or(NoContentResponseSchema),
    signal,
  })(params)
}

export const getJourneyStatusReportDataService = (
  id: string,
  params: GetJourneyReportRequest,
  signal?: AbortSignal
) => {
  return api<GetJourneyReportDataRequest, GetReportDataResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EXPORT_REPORT_STATUS_REPORT_DATA).withId(id).build(),
    requestSchema: GetJourneyReportRequestSchema,
    responseSchema: GetReportDataResponseSchema.or(NoContentResponseSchema),
    signal,
  })(params)
}

export const getTemplatesService = ({type, ...params}: GetTemplatesRequest, signal?: AbortSignal) => {
  return api<GetTemplatesRequest, GetTemplatesResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.LOAD_TEMPLATES).withNotificationType(type).build(),
    requestSchema: GetTemplatesRequestSchema,
    responseSchema: GetTemplatesResponseSchema,
    signal,
  })(params)
}
