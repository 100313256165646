import {PageableResponseSchema} from '@/types/Pagination'
import {z} from 'zod'

export const TaskType = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  active: z.boolean(),
})

export const TaskTypesResponseSchema = PageableResponseSchema(TaskType)

export const TaskTypesRequestSchema = z.void()

export type GetTaskTypesRequest = z.infer<typeof TaskTypesRequestSchema>

export type GetTaskTypesResponse = z.infer<typeof TaskTypesResponseSchema>
