import {pathBuilder} from '@/helpers/pathBuilder'
import {
  GetTemplatesRequest,
  GetTemplatesRequestSchema,
  GetTemplatesResponse,
  GetTemplatesResponseSchema,
} from '@/types/Templates'
import {ENDPOINTS} from '../config/constants'
import api, {ContentType, HTTPMethod} from '../helpers/api'
import {
  GetNBAStatusRequest,
  GetNBAStatusRequestSchema,
  GetNBAStatusResponse,
  GetNBAStatusResponseSchema,
} from '../types/NBAExecutionStatus'
import {
  CreateNBARequest,
  CreateNBARequestSchema,
  CreateNBAResponse,
  CreateNBAResponseSchema,
  DeleteNBARequestSchema,
  DeleteNBAResponse,
  DeleteNBAResponseSchema,
  GetNBAReportDataRequest,
  GetNBAReportRequest,
  GetNBAReportRequestSchema,
  GetNBARequest,
  GetNBARequestSchema,
  GetNBAResponse,
  GetNBAResponseSchema,
  GetNBAsRequest,
  GetNBAsRequestSchema,
  GetNBAsResponse,
  GetNBAsResponseSchema,
  GetReportDataResponse,
  GetReportDataResponseSchema,
  GetReportResponse,
  GetReportResponseSchema,
  NoContentResponse,
  NoContentResponseSchema,
  PublishNBARequest,
  PublishNBARequestSchema,
  SaveNBARequest,
  SaveNBARequestSchema,
  SaveNBAResponse,
  SaveNBAResponseSchema,
} from '../types/NBAs'
import {
  FileDeleteBodyRequest,
  FileDeleteBodyRequestSchema,
  FileDeleteRequest,
  FileDeleteResponse,
  FileDeleteResponseSchema,
  FileUploadRequest,
  FileUploadRequestSchema,
  FileUploadResponse,
  FileUploadResponseSchema,
  ProccessFileRequest,
  ProccessFileRequestSchema,
  ProccessFileResponse,
  ProccessFileResponseSchema,
} from '@/types/upload'

export const getNBAsService = (params: GetNBAsRequest, signal?: AbortSignal) => {
  return api<GetNBAsRequest, GetNBAsResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_NBAS,
    requestSchema: GetNBAsRequestSchema,
    responseSchema: GetNBAsResponseSchema,
    signal,
  })(params)
}

export const getNBAService = (id: string, signal?: AbortSignal) => {
  return api<GetNBARequest, GetNBAResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EDIT_NBA).withId(id).build(),
    requestSchema: GetNBARequestSchema,
    responseSchema: GetNBAResponseSchema,
    signal,
  })()
}

export const deleteNBAService = (id: string) => {
  return api<GetNBARequest, DeleteNBAResponse>({
    method: HTTPMethod.DELETE,
    path: pathBuilder(ENDPOINTS.EDIT_NBA).withId(id).build(),
    requestSchema: DeleteNBARequestSchema,
    responseSchema: DeleteNBAResponseSchema,
  })()
}

export const saveNBAService = api<SaveNBARequest, SaveNBAResponse>({
  method: HTTPMethod.POST,
  path: ENDPOINTS.SAVE_NBA,
  requestSchema: SaveNBARequestSchema,
  responseSchema: SaveNBAResponseSchema,
})

export const getRequiresInterventionReportService = (id?: string, signal?: AbortSignal) => {
  return api<GetNBARequest, GetReportResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: `${ENDPOINTS.EXPORT_REQUIRES_INTERVENTION}?nbaId=${id}`,
    requestSchema: GetNBARequestSchema,
    responseSchema: GetReportResponseSchema.or(NoContentResponseSchema),
    signal,
  })()
}

export const getRequiresInterventionReportDataService = (id?: string, signal?: AbortSignal) => {
  return api<GetNBARequest, GetReportDataResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: `${ENDPOINTS.EXPORT_REQUIRES_INTERVENTION}?nbaId=${id}`,
    requestSchema: GetNBARequestSchema,
    responseSchema: GetReportDataResponseSchema.or(NoContentResponseSchema),
    signal,
  })()
}

export const getNBAStatusService = (id: string, signal?: AbortSignal) => {
  return api<GetNBAStatusRequest, GetNBAStatusResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.NBA_STATUS).withId(id).build(),
    requestSchema: GetNBAStatusRequestSchema,
    responseSchema: GetNBAStatusResponseSchema,
    signal,
  })()
}

export const getNBAStatusReportService = (id: string, params: GetNBAReportRequest, signal?: AbortSignal) => {
  return api<GetNBAReportRequest, GetReportResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EXPORT_REPORT_STATUS_REPORT).withId(id).build(),
    requestSchema: GetNBAReportRequestSchema,
    responseSchema: GetReportResponseSchema.or(NoContentResponseSchema),
    signal,
  })(params)
}

export const getNBAStatusReportDataService = (id: string, params: GetNBAReportRequest, signal?: AbortSignal) => {
  return api<GetNBAReportDataRequest, GetReportDataResponse | NoContentResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.EXPORT_REPORT_STATUS_REPORT_DATA).withId(id).build(),
    requestSchema: GetNBAReportRequestSchema,
    responseSchema: GetReportDataResponseSchema.or(NoContentResponseSchema),
    signal,
  })(params)
}

export const getTemplatesService = ({type, ...params}: GetTemplatesRequest, signal?: AbortSignal) => {
  return api<GetTemplatesRequest, GetTemplatesResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.LOAD_TEMPLATES).withNotificationType(type).build(),
    requestSchema: GetTemplatesRequestSchema,
    responseSchema: GetTemplatesResponseSchema,
    signal,
  })(params)
}

export const createNBAService = (params: CreateNBARequest, signal?: AbortSignal) => {
  return api<CreateNBARequest, CreateNBAResponse>({
    method: HTTPMethod.POST,
    path: ENDPOINTS.SAVE_NBA,
    requestSchema: CreateNBARequestSchema,
    responseSchema: CreateNBAResponseSchema,
    signal,
  })(params)
}

export const updateNBAService = (payload: CreateNBARequest) =>
  api<CreateNBARequest, CreateNBAResponse>({
    method: HTTPMethod.PUT,
    // path: pathBuilder(ENDPOINTS.EDIT_NBA).withId(id).build(),
    path: ENDPOINTS.SAVE_NBA,
    requestSchema: CreateNBARequestSchema,
    responseSchema: CreateNBAResponseSchema,
  })(payload)

export const publishNBAService = (payload: PublishNBARequest) => {
  return api<PublishNBARequest, CreateNBAResponse>({
    method: HTTPMethod.PUT,
    path: ENDPOINTS.PUBLISH_NBA,
    requestSchema: PublishNBARequestSchema,
    responseSchema: CreateNBAResponseSchema,
  })(payload)
}

export const uploadFileService = (
  params: FileUploadRequest,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
  signal?: AbortSignal
) => {
  return api<FileUploadRequest, FileUploadResponse>({
    method: HTTPMethod.POST,
    path: ENDPOINTS.UPLOAD,
    requestSchema: FileUploadRequestSchema,
    responseSchema: FileUploadResponseSchema,
    contentType: ContentType.MULTIPART_FORMDATA,
    signal,
    onUploadProgress,
  })(params)
}

export const deleteFileService = (params: FileDeleteRequest, signal?: AbortSignal) => {
  return api<FileDeleteBodyRequest, FileDeleteResponse>({
    method: HTTPMethod.DELETE,
    path: `${ENDPOINTS.DELETE_FILE}?nbaId=${params.nbaId}&fileName=${params.fileName}`,
    requestSchema: FileDeleteBodyRequestSchema,
    responseSchema: FileDeleteResponseSchema,
    signal,
  })()
}

export const proccessManualFiles = (payload: ProccessFileRequest) => {
  return api<ProccessFileRequest, ProccessFileResponse>({
    method: HTTPMethod.PUT,
    path: `${ENDPOINTS.PROCCESS_FILE}?nbaId=${payload.nbaId}&filePath=${payload.filePath}&taskId=${payload.taskId}`,
    requestSchema: ProccessFileRequestSchema,
    responseSchema: ProccessFileResponseSchema,
  })(payload)
}
