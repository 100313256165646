import {z} from 'zod'

export const PaginationModelSchema = z.object({
  pageNumber: z.number(),
  pageSize: z.number(),
  totalElements: z.number(),
  totalPages: z.number(),
})

export function PageableResponseSchema<T extends z.ZodTypeAny>(contentItemSchema: T) {
  return z.object({
    success: z.boolean(),
    content: z.array(contentItemSchema),
    pagination: PaginationModelSchema,
  })
}
