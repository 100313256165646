import {CustomNodeModel} from '@/components/Canvas/customNodes/CustomNodeModel'
import {ModalProps} from '@/shared-components/Modal/Modal'
import {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'

export type UseDeleteStepConfirmationModalProps = {
  node: CustomNodeModel
}

export const useDeleteStepConfirmationModal = ({node}: UseDeleteStepConfirmationModalProps) => {
  const {formatMessage} = useIntl()
  const [modalData, setModalData] = useState<ModalProps>({isOpen: false})

  const deleteStep = useCallback(() => {
    node.remove()
  }, [node])

  const openDeleteModalConfirmation = useCallback(() => {
    setModalData({
      ...modalData,
      isOpen: true,
      title: formatMessage({id: 'NBA_BUILDER.DELETE_STEP_WARNING_MODAL_TITLE'}),
      content: formatMessage({id: 'NBA_BUILDER.DELETE_STEP_WARNING_MODAL_CONTENT'}),
      primaryButtonText: formatMessage({id: 'NBA_BUILDER.DELETE_STEP_WARNING_MODAL_CONFIRM'}),
      onClickPrimaryButton: () => {
        deleteStep()
      },
      onClickSecondaryButton: () => setModalData({isOpen: false}),
      onClose: () => setModalData({isOpen: false}),
    })
  }, [deleteStep, formatMessage, modalData])

  return {
    modalData,
    openDeleteModalConfirmation,
  }
}
